import axios from "axios";
import { useAuthStore } from "@/stores/authStore";
import qs from "qs";

const prodHost = ["franchisee.reappay.net","franchisee.payhub.co.kr"];
const devHost = ["franchisee-dev.reappay.net","franchisee-dev.payhub.co.kr"];
let baseUrl;
let payUrl;

if (prodHost.includes(location.hostname)) {
  baseUrl = "https://api-pg.payhub.co.kr/merchant/v1/";
  payUrl = "https://api-pg.payhub.co.kr/api/v1/";
} else if (devHost.includes(location.hostname)) {
  baseUrl = "https://api-dev-pg.payhub.co.kr/merchant/v1/";
  payUrl = "https://api-dev-pg.payhub.co.kr/api/v1/";
} else if (location.hostname === "localhost" || location.hostname === "127.0.0.1") {
  baseUrl = "http://localhost:8086/merchant/v1/";
  payUrl = "http://localhost:9081/api/v1/";
  // baseUrl = "https://api-dev-pg.payhub.co.kr/merchant/v1/";
  // payUrl = "https://api-dev-pg.payhub.co.kr/api/v1/";
} else {
  baseUrl = "https://api-dev-pg.payhub.co.kr/merchant/v1/";
  payUrl = "https://api-dev-pg.payhub.co.kr/api/v1/";
}

const getAxios = (isAuthorize, isMultiPart, isBlob) => {
  let headers = {};
  if (isAuthorize) {
    const auth = useAuthStore();
    if (auth.checkToken()) {
      headers["Authorization"] = "Bearer " + auth.accessToken;
    }
  }
  if (isMultiPart) {
    headers["Content-Type"] = "multipart/form-data";
  }
  if (isBlob) {
    headers["responseType"] = "blob";
  }
  return axios.create({ baseURL: baseUrl, headers: headers });
};

const getAxiosPay = (isAuthorize) => {
  let headers = {};
  if (isAuthorize) {
    const auth = useAuthStore();
    if (auth.checkToken()) {
      headers["Authorization"] = "Bearer " + auth.accessToken;
    }
  }

  return axios.create({baseURL: payUrl, headers: headers});
}

const paramsSerializer = params => {
  return qs.stringify(params, { arrayFormat: "repeat" });
};

const apiBaseUrl = () => {
  return baseUrl;
}
export { getAxios, getAxiosPay, paramsSerializer, apiBaseUrl };
